<template>
  <div class="postion-relative w-100">
    <template>
      <div
        class="sticky-search-bar"
        :class="{'pb-0': !isBrand, 'retailer-view-tag': !!directRetailerId}"
      >
        <!-- ECommerce Header -->
        <section id="ecommerce-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="ecommerce-header-items">
                <div class="result-toggler">
                  <div class="search-results d-block d-lg-none">
                    {{ totalProducts || 0 }} product{{
                      totalProducts > 1 ? 's' : ''
                    }}
                    found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="ecommerce-searchbar"
          :class="{ 'guest-user': isGuest, 'brand-user': isBrand, 'retailer-user' : isRetailer }"
        >
          <div class="search-section d-flex">
            <div class="search-and-filter">
              <div
                class="search-input position-relative"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="filters.search"
                    placeholder="Search by products, style code..."
                    class="search-product pr-3"
                    @input="searchProducts"
                  />
                </b-input-group>
                <feather-icon
                  icon="SearchIcon"
                  size="18"
                  class="search-icon shop"
                />
              </div>
              <div class="filter-and-delivery-date">
                <b-button
                  variant="outline-primary"
                  class="filter-button-1 border-2 filter-button mr-1"
                  @click="mqShallShowLeftSidebar = true"
                >
                  <b-img
                    :src="filterIcon"
                    alt="Error Icon"
                  />
                  Filter
                </b-button>
                <div
                  class="filter-delivery-date"
                >
                  <div
                    v-if="!isGuest"
                    class="position-relative"
                  >
                    <b-button
                      :variant="showDateFilter ? 'primary': 'outline-primary'"
                      class="filter-date-btn font-weight-bolder px-50"
                      @click="showDateFilter = !showDateFilter"
                    >
                      <feather-icon icon="FilterIcon" />
                      <span class="filter-by-text mr-50"> Filter By </span> <span> Delivery Date </span>
                      <div
                        v-if="selectedAvailabilityDate.length"
                        class="red-dot"
                      />
                      <feather-icon
                        :icon="showDateFilter ? 'ChevronUpIcon':'ChevronDownIcon'"
                        class="postion-absolute position-right-0"
                      />
                    </b-button>
                    <div
                      v-if="showDateFilter"
                      class="custom-popover"
                    >
                      <div class="pb-1">
                        <span class="color-neutral-black font-weight-bolder">Filter By Delivery Date</span>
                      </div>
                      <div>
                        <div class="mb-10-px">
                          Delivery Window
                        </div>
                        <b-button
                          class="delivery-btn"
                          variant="outline-secondary"
                          @click="showFilterDateOptions = !showFilterDateOptions"
                        >
                          Select Delivery Window Dates
                          <feather-icon
                            :icon="showFilterDateOptions ? 'ChevronUpIcon':'ChevronDownIcon'"
                            class="postion-absolute position-right-0 ml-1"
                          />
                        </b-button>
                        <b-list-group
                          v-if="showFilterDateOptions"
                          class="date-list scroll-bar"
                        >
                          <template v-if="filterByAvailabilityDates.length">
                            <b-list-group-item
                              v-for="(date, index) of filterByAvailabilityDates"
                              :key="date"
                              class="d-flex align-items-center"
                              @click="setDateFiler(index)"
                            >
                              <b-form-checkbox
                                v-model="selectedAvailabilityDate"
                                class="shipment-checkbox date-checkbox"
                                :value="index"
                                stacked
                                @change="setDateFiler(index)"
                              />
                              <span class="ml-5-px">{{ date }}</span>
                            </b-list-group-item>
                          </template>
                          <template v-else>
                            <b-list-group-item class="d-flex align-items-center">
                              No Dates Available
                            </b-list-group-item>
                          </template>
                        </b-list-group>
                        <template v-if="selectedAvailabilityDate.length && !showFilterDateOptions">
                          <div class="mt-10-px">
                            {{ selectedAvailabilityDate.length }} Delivery {{ selectedAvailabilityDate.length > 1 ? 'Windows':'Window' }} Selected:
                          </div>
                          <div class="preview-dates scroll-bar">
                            <div
                              v-for="date of selectedAvailabilityDate"
                              :key="date"
                              class="delivery-date-window"
                            >
                              {{ filterByAvailabilityDates[date] }}
                              <feather-icon
                                icon="XIcon"
                                size="16"
                                class="drop-icon"
                                @click="removeDateFilter(date)"
                              />
                            </div>
                          </div>
                        </template>
                      </div>
                      <b-button
                        variant="info"
                        class="w-100 font-weight-bolder mt-10-px"
                        :disabled="!selectedAvailabilityDate.length"
                        @click="filterByDate"
                      >
                        Apply Filters
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-modal
                  id="connection_request"
                  ref="connectionRequest"
                  class="custom-modal"
                  size="lg"
                  hide-footer
                  hide-header
                  hide-header-close
                  no-close-on-backdrop
                  centered
                >
                  <connection-request
                    :brand-id="brandId"
                    :retailer-id="retailerId"
                    @close-modal="closeModal"
                    @request="requestForConnection"
                  />
                </b-modal>
              </div>

              <!-- Select all button -->
              <div class="ml-md-1 mt-1 mt-md-0">
                <select-all-button
                  :is-selected-all="selectCount === collectionData.count"
                  @toggle-select-all="onToggleSelectAll"
                />
              </div>

              <div
                v-if="collectionData.availableCurrencies.length"
                class="ml-md-1 mt-1 mt-md-0"
              >
                <currency-dropdown
                  :base-currency="collectionBaseCurrency"
                  :selected-currency="filters.currency"
                  :currencies="collectionData.availableCurrencies || []"
                  @on-selected-currency="filterByCurrency"
                />
              </div>

            </div>
            <div class="search-and-sort">
              <div
                class="search-input-1 position-relative"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="filters.search"
                    placeholder="Search by products, style code..."
                    class="search-product pr-3"
                    @input="searchProducts"
                  />
                </b-input-group>
                <feather-icon
                  icon="SearchIcon"
                  size="18"
                  class="search-icon shop"
                />
              </div>
              <div>
                <b-button
                  variant="outline-primary"
                  class="filter-button-2 border-2 float-left filter-button px-1 mr-1"
                  @click="mqShallShowLeftSidebar = true"
                >
                  <b-img
                    :src="filterIcon"
                    alt="Error Icon"
                  />
                  Filter
                </b-button>
                <b-button
                  class="custom-icon-btn"
                  :variant="itemViewClass === PRODUCTS_VIEW.GRID_VIEW ? 'outline-info' : 'outline-secondary'"
                  @click="onCompactToggleClick"
                >
                  <LargeGridViewIcon />
                </b-button>
                <b-button
                  class="custom-icon-btn"
                  :variant="itemViewClass === PRODUCTS_VIEW.COMPACT_GRID_VIEW ? 'outline-info' : 'outline-secondary'"
                  @click="onCompactToggleClick"
                >
                  <CompactGridViewIcon />
                </b-button>
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sortBy.text"
                  right
                  variant="outline-primary"
                  class="custom-dropdown-radius"
                >
                  <b-dropdown-item
                    v-for="sortOption in sortByOptions"
                    :key="sortOption.value"
                    @click="sortBy=sortOption"
                  >
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div
            v-if="!isBrand"
            class="d-flex col-12 pl-2-px align-items-center group-by pr-0"
          >
            <div>
              <span class="color-grey-sult font-size-16-px">Group Products by: </span>
              <b-dropdown
                variant="none"
                class="group-dropdown"
                no-caret
              >
                <template #button-content>
                  <span class="pr-2-px font-size-16-px"> {{ selectedGroup || defaultGroup }}</span>
                  <feather-icon
                    class="align-middle"
                    icon="ChevronDownIcon"
                    size="16"
                  />
                </template>
                <div class="heading">
                  Group By
                </div>
                <b-dropdown-item
                  v-for="group in groups"
                  :key="group"
                  :active="selectedGroup===group"
                  @click="groupProducts(group)"
                >
                  {{ group }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div
              v-if="selectedWishlist"
              class="ml-1 ml-auto"
            >
              <b-button
                id="select_wishlist_button"
                variant="outline-primary"
                @click="onClickSelectWishlist"
              >
                <div
                  class="d-flex"
                  :class="{
                    'has-value': !!(selectedWishlist && selectedWishlist.name),
                  }"
                >
                  <feather-icon
                    icon="HeartIcon"
                    size="16"
                    class="text-danger"
                  />
                  <span class="ml-1">
                    {{
                      !!selectedWishlist
                        ? `${textTruncate(
                          selectedWishlist.name,
                          true,
                          20,
                          15
                        )}(${selectedWishlist.count || 0})`
                        : 'Select a wishlist'
                    }}
                  </span>
                </div>
              </b-button>
              <b-tooltip
                target="select_wishlist_button"
                variant="primary"
                placement="bottomleft"
              >
                {{ selectedWishlist.name }}
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <!-- Overlay -->
      <div
        v-if="totalProducts"
        class="mt-1"
      >
        <shop-products
          :products="products"
          :wishlists="wishlists"
          :selected-wishlist="selectedWishlist"
          :is-show-wsp="isShowWsp"
          :direct-retailer-id="directRetailerId"
          :is-brand-not-accessible="isBrandNotAccessible"
          :connection-request-status="connectionRequestStatus"
          :inactive-collection="inactiveCollection"
          :collection-id="collectionId"
          :has-direct-retailers="hasDirectRetailers"
          :react-handle-cart-action-click="reactHandleCartActionClick"
          :item-view-class="itemViewClass"
          :react-toggle-product-in-wishlist="reactToggleProductInWishlist"
          :add-multiple-items-cart-action-click="addMultipleItemsCartActionClick"
          :is-check-box-disabled="false"
          :is-disable-create-wishlist="isDisableCreateWishlist"
          :collection-products-count="collectionData.count"
          @update-products-by-wishlist="updateProductsByWishlistId"
          @on-deselect-products="deselectedProducts = $event"
          @on-select-all-move-to-wishlist="onSelectAllMoveToWishlist($event)"
        />
        <tool-kit
          v-if="products.length"
          :toolkit-page="TOOLKIT_PAGE.SHOP_PRODUCTS"
        />
        <!-- Infinite Scroll Loading -->
        <section
          v-if="continueLoading"
          class="mt-4"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-spinner
                ref="loadingIcon"
                class="loading-icon"
                label="Loading..."
              />
            </b-col>
          </b-row>
        </section>
      </div>
      <div v-else>
        <empty-products-message v-if="filters.search" />
      </div>

      <!-- Sidebar -->
      <portal
        v-if="filterSetup"
        to="content-renderer-sidebar-detached-left"
      >
        <shop-filter
          :filters="filters"
          :filter-options="filterOptions"
          :whole-sale-enable="!isGuest && isShowWsp"
          :total-products="totalProducts"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @update-filters="fetchShopProducts"
          @close-sidebar="closeSideBar"
        />
      </portal>
    </template>
    <add-products-selection-hint-modal />
    <b-modal
      id="download_catalog"
      centered
      title="Download Catalogue"
      hide-footer
    >
      <div class="mb-2 order-download-modal">
        <div class="mt-1">
          <div class="input-container w-100">
            <label
              :key="DOWNLOAD_OPTIONS.CATALOG.XLSX"
              class="selected-color w-100"
            >
              <input
                type="radio"
                name="exportType"
                :value="DOWNLOAD_OPTIONS.CATALOG.XLSX.XLSX"
                checked
              >
              <span class="text-nowrap mb-1">
                <b-card-text class="ml-1">
                  Download Excel
                </b-card-text>
              </span>
            </label>
            <b-button
              class="float-right my-1 w-100"
              variant="primary"
              :disabled="downloading"
              @click="downloadCollection"
            >
              {{ downloading ? 'Downloading...' : 'Download Collection' }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CompactGridViewIcon from '@/@core/assets/svg-components/CompactGridViewIcon.vue'
import LargeGridViewIcon from '@/@core/assets/svg-components/LargeGridViewIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import analytics, { getCollectionMixpanelObj } from '@/@core/utils/analytics'
import { setFilter } from '@/common-utils'
import constants, {
 DOWNLOAD_TYPES, FILE_FORMATS, PRODUCTS_LIMIT, PRODUCTS_VIEW, TOOLKIT_PAGE,
} from '@/constants'
import store from '@/store'
import { DOWNLOAD_COLLECTION, FETCH_COLLECTION_PRODUCT_FILTERS } from '@/store/modules/collection.module'
import { FETCH_DIRECT_RETAILERS } from '@/store/modules/connections.module'
import {
SET_REPLACE_CART_PRODUCT, UPDATE_SELECT_COUNT, UPDATE_SELECT_TYPE, MULTI_SELECT_PAGES,
isEnabledHintPopup,
UPDATE_SELECTED_DIRECT_RETAILER,
FETCH_WISHLISTS,
UPDATE_TOTAL_PRODUCTS_COUNT,
UPDATE_IS_SELECTED_ALL_PRODUCTS,
ADD_PRODUCT_TO_WISHLIST,
} from '@/store/modules/shop.module'
import ConnectionRequest from '@/views/apps/wallet/ConnectionRequest.vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { apiToastSuccess, apiToastWarning } from '@core/utils/toast'
import {
debounce,
elementInViewport,
formatObject,
textTruncate,
} from '@core/utils/utils'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
computed,
onMounted,
onUnmounted,
ref,
watch,
} from '@vue/composition-api'
import {
BButton,
BCol,
BDropdown,
BDropdownItem,
BFormCheckbox,
BFormInput,
BImg,
BInputGroup,
BListGroup,
BListGroupItem,
BModal,
BRow,
BSpinner,
VBModal,
VBTooltip,
BCardText,
BTooltip,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import CurrencyDropdown from '@/views/components/currency-dropdown/CurrencyDropdown.vue'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import SelectAllButton from '@/views/components/select-all-btn/SelectAllButton.vue'
import { useEcommerceUi } from '../useEcommerce'
import AddProductsSelectionHintModal from './AddProductsSelectionHintModal.vue'
import EmptyProductsMessage from './EmptyProductsMessage.vue'
import ShopFilter from './ShopFilter.vue'
import ShopProducts from './ShopProducts.vue'
import {
useShopFiltersSortingAndPagination,
useShopRemoteData,
useShopUi,
} from './useECommerceShop'

const { formatCurrency } = utils

const { BRAND_ACCESS, ROLES } = c
const { CONNECTION_REQUEST_STATUS, DOWNLOAD_OPTIONS, TRACKS } = constants
const { MULTI_SELECT_PRODUCTS } = TRACKS.ACTIONS

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormCheckbox,
    BImg,
    ShopProducts,
    BModal,
    AddProductsSelectionHintModal,
    BCardText,
    BTooltip,
    // SFC
    ShopFilter,
    FeatherIcon,
    ConnectionRequest,
    BListGroup,
    BListGroupItem,
    LargeGridViewIcon,
    CompactGridViewIcon,
    EmptyProductsMessage,
    CurrencyDropdown,
    SelectAllButton,
},
  props: {
    collectionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      productList: [],
      retailerId: null,
      itemViewClass: PRODUCTS_VIEW.GRID_VIEW,
      TOOLKIT_PAGE,
      PRODUCTS_VIEW,
      filterIcon: require('@/assets/images/icons/filterIcon.svg'),
      searchText: '',
      textTruncate,
      isDisableCreateWishlist: false,
    }
  },
  computed: {
    isShowWsp() {
      return !(this.isWspHidden && this.connectionRequestStatus !== CONNECTION_REQUEST_STATUS.APPROVED)
    },
    isGuest() {
      return store.getters.isGuest
    },
    ...mapState({
      profileData: state => state.auth.profileData,
      selectCount: state => state.shop.selectCount,
    }),
  },
  created() {
    this.$root.$refs.shopItems = this
    store.commit(UPDATE_SELECT_TYPE, MULTI_SELECT_PAGES.SHOP)
    if (this.isRetailer) {
      this.retailerId = this.profileData?._id
      analytics.track(constants.TRACKS.PAGES.SHOP)
    }
    this.loadWishlists()
  },
  destroyed() {
    store.commit(UPDATE_SELECT_COUNT, 0)
    store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
    analytics.track(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_END_VIEWING_COLLECTION : constants.TRACKS.ACTIONS.RETAILER_END_VIEWING_COLLECTION, this.collectionData)
  },
  methods: {
    onToggleSelectAll() {
      const isClickedSelectAll = this.selectCount !== this.collectionData?.count
      if (isClickedSelectAll) {
        analytics.track(MULTI_SELECT_PRODUCTS.USER_CLICKS_SELECT_ALL_PRODUCTS_IN_COLLECTION_PRODUCTS_PAGE, { collectionDetails: this.collectionData })
      } else {
        analytics.track(MULTI_SELECT_PRODUCTS.USER_CLICKS_DESELECT_ALL_PRODUCTS_IN_COLLECTION_PRODUCTS_PAGE, { collectionDetails: this.collectionData })
      }
      this.$root.$refs.shop.selectAll(isClickedSelectAll)
    },
    onClickSelectWishlist() {
      this.isDisableCreateWishlist = true
      this.$bvModal.show('wishlist-list')
    },
    trackGridView(event) {
      analytics.track(event, formatObject({ noOfProductsInCollection: this.totalProducts }))
    },
     onCompactToggleClick() {
      if (this.itemViewClass === PRODUCTS_VIEW.GRID_VIEW) {
        this.itemViewClass = PRODUCTS_VIEW.COMPACT_GRID_VIEW
        this.trackGridView(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_SELECTS_COMPACT_VIEW : constants.TRACKS.ACTIONS.RETAILER_SELECTS_COMPACT_VIEW)
      }
      else {
        this.itemViewClass = PRODUCTS_VIEW.GRID_VIEW
        this.trackGridView(this.isBrand ? constants.TRACKS.ACTIONS.BRAND_SELECTS_GENERAL_VIEW : constants.TRACKS.ACTIONS.RETAILER_SELECTS_GENERAL_VIEW)
      }
    },

    closeModal() {
      this.$refs.connectionRequest.hide()
    },
    onClickGuestSignUp() {
      this.$root.$refs.guestSignup.openModal()
    },

  },

  setup(props, { root }) {
    const { sortBy, sortByOptions } = useShopFiltersSortingAndPagination()
    const {
      handleCartActionClick,
      toggleProductInWishlist,
      addMultipleItemsInCart,
      parseProductImage,
    } = useEcommerceUi()
    const collectionData = ref({})
    const selectedWishlistId = ref('')
    const selectedWishlist = ref(null)
    const wishlists = ref([])

    const isBrand = computed(() => root.$store.getters.entityType === ROLES.BRAND)

    const deselectedProducts = ref([])
    const fetchQueryParams = ref({})
    const isSelectedAllProducts = computed(() => root.$store.state.shop.isSelectedAllProducts)
    const collectionBaseCurrency = ref('')

    const reactToggleProductInWishlist = async (product, index, groupIndex, action, wishlistId = null, wishlistName = '', wishlist = null, isCreateWishlist = false) => {
      store.commit(UPDATE_SELECT_COUNT, 0)
      const wishlistIdValue = !isCreateWishlist ? wishlistId || selectedWishlistId.value : null
      const updatedProduct = await toggleProductInWishlist({
 product, wishlistId: wishlistIdValue, action, wishlistName, wishlist, collectionName: collectionData.value?.collectionName,
})
      if (Array.isArray(updatedProduct)) {
        updatedProduct.forEach((updatedProducts, ind) => {
          Vue.set(products.value, index[ind], updatedProducts)
        })
      } else {
        if (products.value[groupIndex]?.products.length) Vue.set(products.value[groupIndex].products, index, updatedProduct)
        if (isRetailer.value) {
          if (product.isInWishlist) {
            analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_TO_WISHLIST, formatObject({ collectionName: collectionData.value?.collectionName, createdBy: ROLES.RETAILER }))
          } else {
            analytics.track(constants.TRACKS.ACTIONS.RETAILER_REMOVES_FROM_WISHLIST, formatObject({ collectionName: collectionData.value?.collectionName, createdBy: ROLES.RETAILER }))
          }
        }
      }
      loadWishlists(isCreateWishlist)
    }

    const reactHandleCartActionClick = async (product, index, groupIndex) => {
      const updatedProduct = await handleCartActionClick({ product })
      if (updatedProduct) {
        Vue.set(products.value[groupIndex].products, index, updatedProduct)
        handleReplaceCartProduct()
      }
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_TO_CART, formatObject({ collectionName: collectionData.value?.collectionName }))
    }

    const addMultipleItemsCartActionClick = async (product, index, groupIndex) => {
      let deselectedProductIds = []
        let filterQueries = {}
        if (isSelectedAllProducts.value) {
          fetchQueryParams.value.isSelectedAllProducts = true
          fetchQueryParams.value.collectionId = props.collectionId
          filterQueries = fetchQueryParams.value
          delete filterQueries.wishlistId
          deselectedProductIds = deselectedProducts.value?.map(p => p._id)
        }
      const updatedProduct = await addMultipleItemsInCart(product, filterQueries, deselectedProductIds)
      store.commit(UPDATE_SELECT_COUNT, 0)
      if (Array.isArray(updatedProduct)) {
        updatedProduct.forEach((updatedProducts, ind) => {
          Vue.set(products.value[groupIndex[ind].groupIndex].products, index[ind], updatedProducts)
        })
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_ADDS_SELECTED_ITEMS_TO_CART, formatObject({ collectionName: collectionData.value?.collectionName, noOfProductsSelected: updatedProduct?.length }))
      }
      handleReplaceCartProduct()
    }

    const handleReplaceCartProduct = async () => {
      const replacingProduct = await root.$store.getters.getReplaceCarProduct
      // Redirect to cart page if the replacing product present
      if (replacingProduct) {
        root.$router.push({ name: 'checkout' })
      }
    }

    const {
      itemView,
      itemViewOptions,
      totalProducts,
      isWspHidden,
      isBrandNotAccessible,
      connectionRequestStatus,
      brandId,
      filterByAvailabilityDates,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // filtering
    const filters = ref({
      search: '',
      page: 0,
      limit: PRODUCTS_LIMIT,
    })
    const filterOptions = ref({})
    const filterSetup = ref(false)
    const selcetedAvailabiltyDateIndex = ref([])
    const selectedAvailabilityDate = ref([])
    const selectedDeliveryDate = []
    const showDateFilter = ref(false)
    const showFilterDateOptions = ref(false)
    let initialWholesalePriceMax
    let initialWholesalePriceMin
    let initialRetailPriceMax
    let initialRetailPriceMin
    let productsCount = 0

    const inactiveCollection = ref(false)
    const groups = ref({})
    const defaultGroup = ref('None')
    const selectedGroup = ref(defaultGroup.value)

    const isFilterApplied = ref(false)
    const directRetailerId = ref(null)

    const isRetailer = computed(() => root.$store.getters.isRetailer)
    const isGuest = computed(() => root.$store.getters.isGuest)
    const profile = computed(() => root.$store.getters.profile)
    const hasDirectRetailers = ref(false)
    const downloading = ref(false)

    const groupProducts = group => {
      selectedGroup.value = group
      isFilterApplied.value = true
      filters.value.page = 0
      fetchShopProducts()
      if (isRetailer.value) {
        analytics.track(constants.TRACKS.ACTIONS.RETAILER_GROUPS_PRODUCTS, formatObject({ selectedGroup: group }))
      }
    }

    const downloadCollection = () => {
      const payload = {
        retailerId: directRetailerId.value,
        respectiveId: props.collectionId,
        fileType: FILE_FORMATS.XLSX,
        downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
      }
      downloading.value = true
        root.$store.dispatch(DOWNLOAD_COLLECTION, { payload })
        .then(res => {
            apiToastSuccess(res.data.message)
            root.$bvModal.hide('download_catalog')
            root.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
          })
          .catch(err => {
            apiToastWarning(err)
          })
          .finally(() => {
            downloading.value = false
          })
    }

    const openDownloadCollection = () => {
      downloading.value = false
      root.$bvModal.show('download_catalog')
    }

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = (newFilter, resetFilters = false) => new Promise((resolve, reject) => {
      if (newFilter && typeof newFilter === 'object') {
        isFilterApplied.value = true
        filters.value = newFilter
        filters.value.page = 0
        if (initialWholesalePriceMax === filters.value.wholesalePriceMax) delete filters.value.wholesalePriceMax
        if (initialWholesalePriceMin === filters.value.wholesalePriceMin) delete filters.value.wholesalePriceMin
        if (initialRetailPriceMax === filters.value.retailPriceMax) delete filters.value.retailPriceMax
        if (initialRetailPriceMin === filters.value.retailPriceMin) delete filters.value.retailPriceMin
      }
      const fetchParam = {
        sortBy: sortBy.value.value,
        page: filters.value.page,
        // limit: filters.value.limit
      }
      if (profile.value?.isDemo) {
        fetchParam.isDemo = true
      }
      if (selectedWishlistId.value) {
        fetchParam.wishlistId = selectedWishlistId.value
      }
      if (directRetailerId.value) {
        fetchParam.retailerId = directRetailerId.value
      }
      for (const key in filters.value) {
        if (filters.value.hasOwnProperty(key)) {
          if (
            Array.isArray(filters.value[key])
              && filters.value[key].length
          ) {
            fetchParam[key] = filters.value[key].join(',')
          } else {
            fetchParam[key] = filters.value[key]
          }
        }
      }
      if (resetFilters) {
        selcetedAvailabiltyDateIndex.value = []
        selectedAvailabilityDate.value = []
        showDateFilter.value = false
        showFilterDateOptions.value = false
      }
      if (selcetedAvailabiltyDateIndex.value.length) {
        const availabilityDates = selcetedAvailabiltyDateIndex.value.map(date => (
          filterByAvailabilityDates.value[date]
        ))
        fetchParam.availabilityDates = availabilityDates.join(',')
      }
      fetchParam.groupBy = selectedGroup.value || defaultGroup.value
      if (props.collectionId) fetchParam.collectionId = props.collectionId
      // Update filter options
      if (filterSetup.value) updateFilter(fetchParam)
      fetchProducts(fetchParam)
        .then(response => {
          const result = response.data.data
          root.$store.commit(UPDATE_TOTAL_PRODUCTS_COUNT, result.count)
          collectionData.value = result
          if (!fetchParam.currency) {
            collectionBaseCurrency.value = result.products[0]?.products[0]?.currency
          }
          if (result.hasOwnProperty('isCollectionExpired')) {
            inactiveCollection.value = result.isCollectionExpired
          }
          connectionRequestStatus.value = (result.hasOwnProperty('connectionRequestStatus') && result.connectionRequestStatus) || null
          isWspHidden.value = result.hasOwnProperty('isWspHidden') && result.isWspHidden
          isBrandNotAccessible.value = result.hasOwnProperty('isBrandNotAccessible') && result.isBrandNotAccessible
          if (result.page === 0) {
            productsCount = 0
          }
          result.products.forEach(groupedProduct => {
            groupedProduct.products.forEach(product => {
            const productColors = []
            const colors = []
            productsCount++
            product.quantity = product.quantity || 1
            product.items.forEach(item => {
              if (colors.indexOf(item.color) < 0) {
                colors.push(item.color)
                productColors.push({ color: item.color, colorFamily: item.colorFamily })
              }
            })
            product.colors = productColors
            })
          })
          // If the total count greater than products Count it continues loading
          // If the result has 0 products it stops loading
          if (result.count > productsCount) {
            continueLoading.value = true
          }
          else {
            continueLoading.value = false
          }
          // append on loading by scroll
          if (result.page >= 1) {
            if (result.products.length && products.value[products.value.length - 1]._id === result.products[0]._id) {
              products.value[products.value.length - 1].products = products.value[products.value.length - 1].products.concat(result.products[0].products)
              result.products.splice(0, 1)
            }
            products.value = [
              ...products.value,
              ...result.products,
            ]
          }
          // show products to UI
          else {
            products.value = result.products
          }
          totalProducts.value = result.count
          brandId.value = result.brandId
          if (!isFilterApplied.value) {
            analytics.track(
              isBrand.value ? constants.TRACKS.PAGES.BRAND_OPENS_COLLECTION : constants.TRACKS.PAGES.RETAILER_OPENS_COLLECTION,
              formatObject(getCollectionMixpanelObj(result)),
            )
            analytics.track(isBrand.value ? constants.TRACKS.ACTIONS.BRAND_START_VIEWING_COLLECTION : constants.TRACKS.ACTIONS.RETAILER_START_VIEWING_COLLECTION, formatObject(collectionData.value))
          }
          resolve(response)
          if (isBrand.value && localStorage.getItem(isEnabledHintPopup) === 'true') {
            localStorage.setItem(isEnabledHintPopup, false)
            root.$bvModal.show('hint_selection_modal')
          }
          fetchQueryParams.value = fetchParam
        })
        .catch(err => {
          // 404 error - when products is empty
          if (err.response.status === 404) {
            products.value = []
            totalProducts.value = 0
            isLoading.value = false
          } else {
            apiToastWarning(err)
          }
          reject(err)
        })
        if (isFilterApplied.value) {
          analytics.track(isBrand.value ? constants.TRACKS.ACTIONS.BRAND_APPLIES_FILTER_CLN : constants.TRACKS.ACTIONS.RETAILER_APPLIES_FILTER_CLN, formatObject(fetchParam))
        }
    })

    const filterByCurrency = (currency = '') => {
      if (!currency) {
        delete filters.value?.currency
      } else {
        filters.value.currency = currency
        filters.value.page = 0
      }
      fetchShopProducts()
    }

    const setWishlist = (wishlistId = '', isCreateWishlist = false) => {
      if (!wishlistId) {
        selectedWishlistId.value = ''
        if (wishlists.value?.length) {
          selectedWishlist.value = wishlists.value[0]
          selectedWishlistId.value = selectedWishlist.value._id
          if (isCreateWishlist) {
            fetchShopProducts()
          }
        }
        return
      }
      selectedWishlistId.value = wishlistId

      selectedWishlist.value = wishlists.value.find(wishlist => wishlist._id === selectedWishlistId.value)
    }

    const updateProductsByWishlistId = ({ wishlistId }) => {
      setWishlist(wishlistId)
      fetchShopProducts()
    }

    watch(
      [sortBy],
      () => {
        if (filterSetup.value) {
          filters.value.page = 0
          fetchShopProducts()
        }
      },
      {
        deep: true,
      },
    )

    // update filter options when select a menu
    const updateFilter = async fetchParam => {
      if (directRetailerId.value) {
        fetchParam.retailerId = directRetailerId.value
      }
      await root.$store
        .dispatch(FETCH_COLLECTION_PRODUCT_FILTERS, fetchParam)
        .then(res => {
          const filterMenus = res.data.data[0]
          if (filterMenus !== undefined) {
            const filerMenuFields = ['lines', 'departments', 'categories', 'subCategories', 'gender', 'colors', 'sizes', 'subLines', 'groups', 'subGroups', 'keyStyles']
            filerMenuFields.forEach(field => {
                filterOptions.value[field] = setFilter(
                filterOptions.value[field],
                fetchParam[field],
                filterMenus[field],
              )
            })
            filterByAvailabilityDates.value = setFilter(
              filterByAvailabilityDates.value,
              fetchParam.availabilityDates,
              filterMenus.availabilityDates,
            )
            groups.value = filterMenus.groupBy
          }
        })
    }

    // setup filters
    const fetchShopFilters = async () => {
      await root.$store
        .dispatch(FETCH_COLLECTION_PRODUCT_FILTERS, {
          collectionId: props.collectionId,
          isDemo: !!profile.value?.isDemo,
          retailerId: directRetailerId.value ? directRetailerId.value : null,
      })
        .then(res => {
          filterOptions.value = res.data.data[0]
          for (const key in filterOptions.value) {
            if (filterOptions.value.hasOwnProperty(key)) {
              filters.value[key] = []
              if (key.indexOf('PriceMax') > -1) {
                filterOptions.value[key] = Math.round(filterOptions.value[key]) + 1
              }
              if (key.indexOf('PriceMin') > -1) {
                filterOptions.value[key] = Math.round(filterOptions.value[key]) - 1
              }
            }
          }
          if (filterOptions.value) {
          if (filterOptions.value.availabilityDates.length) {
            filterByAvailabilityDates.value = filterOptions.value.availabilityDates.filter(date => date)
          }
          filterOptions.value.retailPrice = [
            filterOptions.value.retailPriceMin,
            filterOptions.value.retailPriceMax,
          ]
          filterOptions.value.wholesalePrice = [
            filterOptions.value.wholesalePriceMin,
            filterOptions.value.wholesalePriceMax,
          ]
          groups.value = filterOptions.value.groupBy
          initialWholesalePriceMax = filterOptions.value.wholesalePriceMax
          initialWholesalePriceMin = filterOptions.value.wholesalePriceMin
          initialRetailPriceMax = filterOptions.value.retailPriceMax
          initialRetailPriceMin = filterOptions.value.retailPriceMin
          filterSetup.value = true
          }
          getDirectRetailers()
        })
    }

    if (!filterSetup.value) fetchShopFilters()
    const loadWishlists = async (isCreateWishlist = false) => {
      await store
        .dispatch(FETCH_WISHLISTS, { force: true, collectionId: props.collectionId })
        .then(resWishlists => {
          wishlists.value = resWishlists.filter(wishlist => !wishlist.isReadOnly)
        })
        setWishlist(selectedWishlistId.value || '', isCreateWishlist)
           // fetch products
    fetchShopProducts().then(response => {
      // update breadcrumb
      root.$route.meta.breadcrumb = root.$route.meta.breadcrumb.map(item => {
        if (item.dynamic === true) {
          if (item.hasOwnProperty('routeName')) {
            // display brand name instead for retailer view
            if (isRetailer.value || isGuest.value) {
              item.routeName = response.data.data.brandName
              item.to = {
                name: 'brand/detail',
                params: { brandId: response.data.data.brandId },
              }
            }
            // display collection list
            else {
              item.routeName = 'Collections'
              item.to = { name: 'collections' }
            }
          } else if (item.hasOwnProperty('collectionName')) {
            item.collectionName = response.data.data.collectionName
          }
        }
        return item
      })
    })
    }

    // Load wishlists
    loadWishlists()

    const getDirectRetailers = async () => {
      const access = profile.value?.access || []
      if (isBrand && access.includes(BRAND_ACCESS.DIRECT)) {
        const directRetailers = await root.$store.dispatch(FETCH_DIRECT_RETAILERS)
        if (directRetailers?.length) {
          hasDirectRetailers.value = true
        }
      }
    }

    const setDirectRetailerInfo = (id = null) => {
      directRetailerId.value = id
      products.value = []
      isLoading.value = true
      filters.value.currency = ''
      filters.value.page = 0
      fetchShopProducts().then(() => {
        isLoading.value = false
      })
    }

    // loading on scroll
    const isLoading = ref(false)
    const loadingIcon = ref(null)
    const continueLoading = ref(false)

    const onScrollLoader = () => {
      if (!isLoading.value && elementInViewport(loadingIcon.value)) {
        isLoading.value = true
        filters.value.page++
        fetchShopProducts().then(() => {
          isLoading.value = false
        })
      }
    }

    const requestForConnection = () => {
      connectionRequestStatus.value = CONNECTION_REQUEST_STATUS.PENDING
    }

    const filterByDate = () => {
      isFilterApplied.value = true
      // track obj for mixpanel
      const trackObj = {
        'Selected Delivery Dates': selectedDeliveryDate.length ? selectedDeliveryDate : [],
      }
      analytics.track(isBrand.value ? constants.TRACKS.ACTIONS.BRAND_APPLIES_DELIVERY_DATES_FILTER : constants.TRACKS.ACTIONS.RETAILER_APPLIES_DELIVERY_DATES_FILTER, trackObj)
      selcetedAvailabiltyDateIndex.value = selectedAvailabilityDate.value.slice()
      showDateFilter.value = false
      showFilterDateOptions.value = false
      filters.value.page = 0
      fetchShopProducts()
    }
    const setDateFiler = dateIndex => {
      const index = selectedAvailabilityDate.value.indexOf(dateIndex)
        if (index !== -1) {
          selectedAvailabilityDate.value.splice(index, 1)
          selectedDeliveryDate.splice(index, 1)
        }
        else {
          selectedAvailabilityDate.value.push(dateIndex)
          selectedDeliveryDate.push(filterByAvailabilityDates.value[dateIndex])
        }
    }
    const removeDateFilter = dateIndex => {
      const index = selectedAvailabilityDate.value.indexOf(dateIndex)
        if (index !== -1) {
          selectedAvailabilityDate.value.splice(index, 1)
        }
      const filterIndex = selcetedAvailabiltyDateIndex.value.indexOf(dateIndex)
        if (filterIndex !== -1) {
          selcetedAvailabiltyDateIndex.value.splice(index, 1)
          fetchShopProducts()
        }
    }
    const searchProducts = debounce(() => {
        isFilterApplied.value = true
        filters.value.page = 0
        fetchShopProducts()
      }, 1000)

    const closeSideBar = () => {
      mqShallShowLeftSidebar.value = false
    }

    const onSelectAllMoveToWishlist = (obj = {}) => {
      const { wishlistId, wishlistName, isCreateWishlist } = obj
      const payload = {
          deselectedProducts: deselectedProducts.value?.map(p => p._id),
        }
      let filterQueries = {}
          fetchQueryParams.value.isSelectedAllProducts = true
          fetchQueryParams.value.collectionId = props.collectionId
          filterQueries = fetchQueryParams.value
          delete filterQueries.page
          delete filterQueries.limit
          delete filterQueries.wishlistId
      if (isCreateWishlist) {
        payload.name = wishlistName
      } else {
        payload.wishlistId = wishlistId
      }
      store.dispatch(ADD_PRODUCT_TO_WISHLIST, { payload, queryParams: filterQueries })
        .then(res => {
          apiToastSuccess(res.data.message)
          store.commit(UPDATE_SELECT_COUNT, 0)
          store.commit(UPDATE_IS_SELECTED_ALL_PRODUCTS, false)
          deselectedProducts.value = []
          loadWishlists(isCreateWishlist)
        })
        .catch(err => {
          apiToastWarning(err)
        })
    }

    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
      window.addEventListener('popstate', () => {
        root.$store.commit(SET_REPLACE_CART_PRODUCT, null)
      })
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
      window.removeEventListener('popstate', () => {
        root.$store.commit(SET_REPLACE_CART_PRODUCT, null)
      })
      root.$store.commit(UPDATE_SELECTED_DIRECT_RETAILER, null)
    })

    return {
      isLoading,
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      filterSetup,
      sortBy,
      sortByOptions,
      loadingIcon,
      continueLoading,
      deselectedProducts,
      collectionBaseCurrency,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      fetchShopProducts,
      reactToggleProductInWishlist,
      reactHandleCartActionClick,
      addMultipleItemsCartActionClick,
      isWspHidden,
      isBrandNotAccessible,
      connectionRequestStatus,
      brandId,
      // Filter By Date variables
      filterByAvailabilityDates,
      selcetedAvailabiltyDateIndex,
      selectedAvailabilityDate,
      showDateFilter,
      showFilterDateOptions,
      groups,
      selectedGroup,
      // Filter By Date functions
      filterByDate,
      setDateFiler,
      removeDateFilter,
      // Group products
      defaultGroup,
      groupProducts,
      // Filter By Search products
      searchProducts,
      // showelements,
      // useShopRemoteData
      products,
      inactiveCollection,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      closeSideBar,
      // extra
      parseProductImage,
      formatCurrency,
      requestForConnection,
      CONNECTION_REQUEST_STATUS,
      hasDirectRetailers,
      isRetailer: computed(() => root.$store.getters.entityType === ROLES.RETAILER),
      isBrand,
      setDirectRetailerInfo,
      directRetailerId,
      DOWNLOAD_OPTIONS,
      downloadCollection,
      downloading,
      openDownloadCollection,
      updateProductsByWishlistId,
      loadWishlists,
      setWishlist,
      selectedWishlistId,
      selectedWishlist,
      wishlists,
      onSelectAllMoveToWishlist,
      collectionData,
      filterByCurrency,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-shop.scss';
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>
