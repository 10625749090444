<template>
  <div
    v-if="!isLoadingCart"
    class="ecommerce-application"
  >
    <div
      v-if="directRetailers.length"
      class="mt-1 d-flex align-items-center"
    >
      Select Retailer <span class="text-danger"> * </span>
      <b-dropdown
        class="filter-dropdown w-25 ml-1"
        variant="outline-none"
        block
        no-caret
        menu-class="w-100 filter-dropdown-menu scroll-bar"
        toggle-class="filter-dropdown-toggle"
        no-flip
      >
        <template #button-content>
          <div
            class="d-flex justify-content-between"
            :class="{ 'has-value': selectedRetailer }"
          >
            <span> {{ selectedRetailer ? `${ textTruncate(selectedRetailer.entityName, true, 25, 20)} - ${selectedRetailer.email}` : 'Select Retailer' }} </span>
            <feather-icon icon="ChevronDownIcon" />
          </div>
        </template>
        <div>
          <b-dropdown-form>
            <b-form-input
              v-model="searchText"
              placeholder="Search retailer"
            />
            <feather-icon
              icon="SearchIcon"
              class="dropdown-form-search-icon"
              size="18"
            />
          </b-dropdown-form>
        </div>
        <template v-if="allDirectRetailers.length">
          <b-dropdown-item
            v-for="option in allDirectRetailers"
            :key="option.retailerId"
            :active="option.retailerId === retailerId"
            @click="onSelectRetailer(option)"
          >
            <div>
              <span>
                {{ option.entityName }} - {{ option.email }}
              </span>
            </div>
          </b-dropdown-item>
        </template>
        <b-dropdown-item
          v-else
          disabled
        >
          No matched retailers
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <cart-total
      :key="reloadkey"
      :cart-products="cartProducts"
      :retailer-id="retailerId"
      :brand-notes="notesData"
      is-popup
      :get-cart-products-by-brand="getCartProductsByBrand"
      @remove-product-selected-from-brand="removeProduct"
      @update-notes="updateNotes"
    />
  </div>
  <div v-else>
    <div class="text-center p-4">
      <b-spinner />
    </div>
  </div>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatObject, searchDirectRetailer, textTruncate } from '@/@core/utils/utils'
import {
BDropdown, BDropdownForm, BDropdownItem, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import CartTotal from './CartTotal.vue'

const { TRACKS } = constants

export default {
  components: {
    CartTotal,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BFormInput,
    FeatherIcon,
    BSpinner,
  },
  props: {
    cartProducts: {
      type: Array,
      default: () => [],
    },
    getCartProductsByBrand: {
      type: Function,
      default: () => {},
    },
    isBrandCartUpdated: {
      type: Number,
      default: 0,
    },
    isLoadingCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRetailer: null,
      retailerId: null,
      reloadkey: 1,
      notesData: {},
      allDirectRetailers: [],
      searchText: '',
      textTruncate,
    }
  },
  computed: {
    ...mapState({
      directRetailers: state => state.connections.directRetailers || [],
    }),
  },
  watch: {
    searchText() {
      this.searchRetailer()
    },
    isBrandCartUpdated() {
      this.reloadkey += 1
    },
  },
  emits: ['close-brand-cart-modal'],
  mounted() {
    this.allDirectRetailers = this.directRetailers
  },
  methods: {
    onSelectRetailer(retailer) {
      this.retailerId = retailer.retailerId
      this.selectedRetailer = retailer
      const discount = retailer?.discount || 1
      this.cartProducts.forEach(product => {
        product.discount = discount
      })
      this.searchText = ''
      this.reloadkey += 1
      analytics.track(TRACKS.ACTIONS.BRAND_SELECTS_RETAILER_FOR_ORDER, formatObject({
        retailerId: retailer.retailerId,
        retailerName: retailer.entityName,
      }))
    },
    removeProduct(product) {
      const index = this.cartProducts.findIndex(p => p._id === product._id)
      if (index !== -1) {
        this.cartProducts.splice(index, 1)
        if (this.cartProducts.length === 0) {
          this.$emit('close-brand-cart-modal')
        }
      }
      analytics.track(TRACKS.ACTIONS.BRAND_REMOVES_PRODUCT_FROM_CART, formatObject({
        productId: product._id,
        productName: product.name,
      }))
    },
    updateNotes(notesData = {}) {
      this.notesData = {
        notes: notesData.notes,
        isEditNotes: notesData.isEditNotes,
      }
    },
    searchRetailer() {
      this.allDirectRetailers = searchDirectRetailer(
        this.directRetailers,
        this.searchText,
      )
    },
  },
}
</script>
